import React, { useEffect, useState } from 'react';
import axios from "axios";
import './index.css';
// import tpl from '../../assets/WithdrawalAgreement.js';

const WithdrawalAgreement = () => {
    const [detail, setDetail] = useState({});
    useEffect(() => {
        // let formdata = new FormData();
        // formdata.append('type',new URLSearchParams(props.history.location.type).get('type'))

        axios.get('/api/user/getProtocol?type=4', {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            if (res.data.code === 0) {
                setDetail(res.data.result.content)
            }
        })
    }, [])
    return (
        <>
            <div className='Privacy'>
                {
                    typeof detail === 'string' && <div  className="subtext" dangerouslySetInnerHTML={{ __html: detail }}></div>
                }
            </div>
        </>
    )
}

export default WithdrawalAgreement;