import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './index.css';
import Home from './pages/home';
import Protoal from './pages/protoal';
import Detail from  './pages/detail';
import PetDetail from  './pages/PetDetail';
import NewsDetail from  './pages/newsDetail';
import Support from './pages/support';
import Authentication from './pages/authentication';
import Guide from './pages/guide';
import RegisterAgreement from './pages/registerAgreement';
import Privacy from './pages/privacy';
import PaymentAreement from './pages/paymentAreement';
import SupplementaryAgreement from './pages/supplementaryAgreement';
import WithdrawalAgreement from './pages/withdrawalAgreement';
import PaidServiceGuarantee from './pages/paidServiceGuarantee';
import ProtocolDetail from './pages/protocolDetail';

import 'antd/dist/antd.css';

const App = () => {
    return (
        <>
            <Router>
                <Switch>
                <Route path="/" exact  component={Home} />
                <Route path="/protocol" exact component={Protoal} />
                <Route path="/detail" exact component={Detail} />
                <Route path="/petdetail" exact component={PetDetail} />
                <Route path="/newsdetail" exact component={NewsDetail} />
                <Route path="/support" exact component={Support} />
                <Route path="/authentication" exact component={Authentication} />
                <Route path="/guide" exact component={Guide} />
                {/* 用户注册协议 */}
                <Route path="/registerAgreement" exact component={RegisterAgreement} />
                {/* 隐私政策 */}
                <Route path="/privacy" exact component={Privacy} />
                {/* 用户须知 */}
                <Route path="/paymentAreement" exact component={PaymentAreement} />
                {/* 健康专家板块用户注册补充协议 */}
                <Route path="/supplementaryAgreement" exact component={SupplementaryAgreement} />
                {/* 提现规则 */}
                <Route path="/withdrawalAgreement" exact component={WithdrawalAgreement} />
                {/* 懒洋葱付费服务保障 */}
                <Route path="/paidServiceGuarantee" exact component={PaidServiceGuarantee} />
                {/* 懒洋葱付费服务保障 */}
                <Route path="/protocolDetail" exact component={ProtocolDetail} />
                </Switch>
            </Router>
        </>
    )
}

export default App;