import React, { useEffect, useState } from 'react';
import axios from "axios";
import '../../assets/swiper-bundle.min.css';
import './index.css';
// import { linkToApp } from '../../assets/util';



const PetDetail = (props) => {

    const [detail, setDetail] = useState({});

    const [tagList, setTag] = useState([]);

    useEffect(() => {
        let formdata = new FormData();
        formdata.append('id',new URLSearchParams(props.history.location.search).get('id'))

        axios.post('/api/petCaseDetail', formdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            if (res.data.code === 0) {
                setDetail(res.data.result)
            }
        })
    }, [props.history.location.search])

    useEffect(() => {
        setTag(detail.label)
    },[detail,setTag])

    // const linkTo = () => {
    //     linkToApp();
    // }

    const hideMask = () => {
        document.getElementById('mask').style.display = "none";
    }

    const getContent = (content) => {
        if (content && content.length > 0) {
            return content.replace(/\<img/gi,'<img alt="img" class="rich-img" ')
        }
        return ''
    }

    /**
     * 调用移动端方法
     *
     * @param {*} {name, params, call} 移动端注入的方法名 | 参数
     */
    const callMoblieMethods = ({name, params}) => {
        // console.log(name,params)
        // 移动端安卓的环境
        if(window.android) {
            // 移动端使用java所以不能直接解析json,只能解析字符串或者json字符串
            window.android[name](JSON.stringify(params))
        }
        // 移动端IOS的环境
        if(window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers[name].postMessage(params)
        }
    }

    return (
        <>
            <div className="detailtop">

                <div className="container">
                    <div className="title">
                        { detail.title }
                    </div>
                </div>
                <div className="container">
                    <div className="tags">
                        {
                            tagList && tagList.map((item,index) => {
                                return <span className='tag' key={index} onClick={() => callMoblieMethods({name: 'searchForLabel',params:{ name: item}})}>#{item}</span>
                            })
                        }
                    </div>
                </div>
                <div className="container">
                    <p className="subtitle">案号</p>
                    <p className="subtext">
                        { detail.code }
                    </p>
                </div>
                <div className="container">
                    <p className="subtitle">案由</p>
                    <p className="subtext">
                    { detail.reason }
                    </p>
                </div>
                <div className="container">
                    <p className="subtitle">案件身份</p>
                    <p className="subtext">
                        { detail.plaintiff }
                    </p>
                    <p className="subtext">
                        { detail.defendant }
                    </p>
                </div>
                <div className="container">
                    <p className="subtitle">案件正文</p>
                    <div  className="subtext" dangerouslySetInnerHTML={{ __html: getContent(detail.content) }}>
                    </div>
                </div>
                <div className="container">
                    <p className="subtitle">裁判结果</p>
                    <p className="causeResult subtext">
                        { detail.referee_results }
                    </p>
                </div>
                <div className="container">
                    <p className="subtitle">发布日期</p>
                    <p className="causeDate subtext">
                        { detail.create_time }
                    </p>
                </div>

            </div>

            {/* <div className="detail-footer">
                <p>
                    <img alt="" src={detail.photo} />
                    {detail.nickname}
                </p>
                <p>
                    <span onClick={linkTo}><i className="like">
                        {
                            detail.isHymn ? <img alt="" src="./like.png" /> : <img alt="" src="./like_no.png" /> 
                        }
                    </i>{detail.hymn !== 0 ? detail.hymn : '点赞'}</span>
                    <span onClick={linkTo}>
                        <i className="start">
                            {
                                detail.isCollect ? <img alt="" src="./start_hover.png" /> : <img alt="" src="./start.png" /> 
                            }
                        </i>
                        {detail.collect !== 0 ? detail.collect : '收藏'}</span>
                </p>
            </div> */}

            <div id="mask" onClick={hideMask}>
                <img src ="./tip.png" alt='' />
            </div>

        </>
    )
}

export default PetDetail;