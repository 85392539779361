import React, { useEffect, useState } from 'react';
import axios from "axios";
import SwiperCore, { Pagination,  A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../assets/swiper-bundle.min.css';
import './index.css';
import { linkToApp } from '../../assets/util';


SwiperCore.use([Pagination, A11y]);

const Detail = (props) => {

    

    const [detail, setDetail] = useState({});
    const [color, setColor] = useState([]);
    const [lookPet, setLookPet] = useState([]);
    const [lookMaster, setlookMaster] = useState([]);
    const [lookHelper, setlookHelper] = useState([]);
    const [getColor, setGetColor] = useState([]);
    const [colorArray, setColorArray] =  useState([]);
    const [plateList, setPlateList] =  useState([]);
    


   useEffect(() => {
        axios.post('/api/detail', { id: new URLSearchParams(props.history.location.search).get('id') }).then((res) => {
            if (res.data.code === 0) {
                setDetail(res.data.result)
            }

        })

        axios.post('/api/petColorH5').then((res) => {
            if (res.data.code === 0) {
                setColor(res.data.result);
            }
        }).catch((error) => {
            console.log(error)    
        })


        

        axios.post('/api/lookPetH5').then((res) => {
            if (res.data.code === 0) {
                setLookPet(res.data.result)

            }
        })

        axios.post('/api/lookMasterH5').then((res) => {
            if (res.data.code === 0) {
                setlookMaster(res.data.result)
            }
        })

        axios.post('/api/lookHelperH5').then((res) => {
            if (res.data.code === 0) {
                setlookHelper(res.data.result)
            }
        })

        axios.post('/api/getPlateListH5').then((res) => {
            if (res.data.code === 0) {
                setPlateList(res.data.result)

            }
        })

    }, [props.history.location.search])

    useEffect(() => {
        const content = [];
        color.forEach(item => {
            content.push(item.id)
        })

        setGetColor(content)
       


    }, [color, setGetColor])

    useEffect(() => {
        let colorArray = [];
        color.forEach((item, index) => {
            getColor.forEach((child, last) => {
                if (item.id === detail['coat_color_' + child] ) {
                    colorArray = [...colorArray, child]
                }
                
            })

        })
        console.log(colorArray)
        setColorArray(colorArray)
       
    }, [color, setColorArray, detail, getColor])


    const linkTo = () => {
        linkToApp();
    }

    const linkToNotes = (url) => {
        linkTo()
    }

    // const getColorNumber = (str) => {
    //     if (str) {
    //         let strArr = String(str).split('')
    //         return  'plate' + strArr[strArr.length - 1]
    //     }
    //     return ''
    // }

    const hideMask = () => {
        document.getElementById('mask').style.display = "none";
    }


    const getTime = (date) => {
        const targetDay = new Date(date);
        const year = targetDay.getFullYear();
        const month = targetDay.getMonth() + 1;
        const day = targetDay.getDate();
        const today = new Date();
        const _year = today.getFullYear();
        const _month = today.getMonth() + 1;
        const _day = today.getDate();
        if (year < _year) {
            return `${year}-${month}-${day}`;
        } else {
            if (month < _month) {
                return `${month}-${day}`;
            } else {
                if (day < _day) {
                    if (day === _day - 1) {
                        return `昨天 ${date.substring(11, 16)}`;
                    }
                    return `${month}-${day}`;
                } else {
                    return date.substring(11, 16) 
                }
            }
        }
    }
    

    return (
        <>
            <header className="top-header">
                {
                    [...lookPet, ...lookMaster, ...lookHelper,...plateList].map(item => {
                        return item.id === detail['plate_list_id'] && item.stage_plate
                    })
                }
            </header>
            <Swiper
            spaceBetween={50}
            loop={true} 
            pagination={{
                clickable: true,
                
               
             }}
             
            slidesPerView={0}
           
            >
                {
                 detail.photo_all && detail.photo_all.map((item, index) => {
                    return  <SwiperSlide key={index}><div  className="swiper-slide swiper-detail-slide">
                        <p
                        style={{
                            background: 'url('+item+')',
                            backgroundSize: 'cover',
                            height: '83.2vw'

                        }}
                        >

                        </p>
                       
                    </div></SwiperSlide>
                })

                }
            
            </Swiper>
            {
                [5,7].includes(detail.plate_list_id) && [4,6].includes(detail.status) && <img  style={{ position: 'absolute', top: '36vw', zIndex: 999, left: '50%', marginLeft: '-20vw', width: '36vw' }} src='./expire.png' />
            }

            {
                ([5,7].includes(detail.plate_list_id) && Number(detail.rewards_money)) && <div className='rewardsTips'>
                    ￥<span id='rewards'>{ detail.rewards_money }</span>
                    （赏金已存平台，帮助发帖者解决即可领取）
                </div>
            }
            {
                detail.isPayment && <div className='rewardsTips'>
                    {detail.deadline_time} 到期
                </div>
            }

            <div className="detail-top">
                <strong><i className={ 'plate' + detail['plate_list_id'] }>{
                    [...lookPet, ...lookMaster, ...lookHelper, ...plateList].map(item => {
                        return item.id === detail['plate_list_id'] && item.stage_plate
                    })
                }</i>{detail.title}</strong>
                
                { 
                 (![9,10,11,13,14,15,16,17].includes(detail.plate_list_id)) && <p className='sub_title'>
                    {
                        (parseInt(detail.plate_list_id) === 8 || parseInt(detail.plate_list_id) === 5) && <em >
                        宠物： {detail.pet_name}
                    </em>
                    }
                    <em>种类：
                        {detail.category === 1 && '猫咪'}
                        {detail.category === 0 && '未设置'}
                        {detail.category === 2 && '狗狗'}
                        {detail.category === 3 && '其他'}
                        </em>
                    <em>品种：{detail.varieties}</em>
                   
                    {
                        detail['plate_list_id'] === 1 && <em>目前状态：
                            {detail.state === 1 && '有居所'}
                            {detail.state === 2 && '无居所'}
                            {detail.state === 3 && '未知'}
                            </em>
                    }
                    
                </p>
                }
            </div>

            <div className="detail-bottom">

                <ul>
                    {
                        colorArray.length > 0 &&  <li>
                        <span>毛色</span>
                        <p className="coat-color">
                            {
                                getColor.map((item,index) => {
                                    if(detail['coat_color_'+item]) {
                                        let curColor = color.find(_item => {
                                            return _item.id === detail['coat_color_'+item];
                                        });
                                        return <span key={curColor.id}><i className={'color' + curColor.id}></i>{curColor.color}色</span>
                                    }
                                })
                                // color.map((item, index) => {
                                //     return getColor.map((child, last) => {
                                //         return item.id === detail['coat_color_' + child] && <span key={index}><i className={'color' + (index + 1)}></i>{item.color}色</span>
                                //     })

                                // })
                            }
                        </p>
                    </li>
                    }
                   {
                        parseInt(detail.plate_list_id)  === 8 && <><li className="flexdown">
                       <span>意向宠物</span>
                       <p>
                           {detail.intention}
                       </p>
                   </li>
                   <li className="flexdown">
                       <span>达成方式</span>
                       <p>
                           {detail.reach}
                       </p>
                   </li></>
                   }
                   {
                        parseInt(detail.plate_list_id)  === 2 && <><li >
                       <span>特殊检查</span>
                       <p>
                           {detail.special_check}
                       </p>
                   </li>
                   <li >
                       <span>已承担费用</span>
                       <p>
                           {detail.paid_expense}
                       </p>
                   </li></>
                   }
                   {
                        parseInt(detail.plate_list_id)  === 4 && <li >
                       <span>特殊习惯</span>
                       <p>
                           {detail.special_habit}
                       </p>
                   </li>
                }

                {
                   ([3,4,14,15,16,17].includes(detail.plate_list_id))  &&
                   <li >
                       <span>意向金</span>
                       <p>
                           {detail.earnest_money}
                       </p>
                   </li>
                   
                }

                {
                    (![14,15,16,17].includes(detail.plate_list_id)) &&
                    <li>
                       <span>宠物种类</span>
                       <span style={{ 'lineHeight': '27px' }}>
                            {detail.category === 1 && '猫咪'}
                            {detail.category === 0 && '未设置'}
                            {detail.category === 2 && '狗狗'}
                            {detail.category === 3 && '其他'}
                       </span>
                   </li>
                }
                {
                    detail.my_notes_id ? <li>
                       <span>笔记</span>
                       <p style={{ paddingBottom: 0 }} onClick={ () => linkToNotes(detail.my_notes_id)}>
                       { detail.notes_title }
                        <span style={{ float: 'right',flex: '1','textAlign': 'right' }}>&gt;</span>
                       </p>
                   </li> : ''
                }

                {
                    detail.plate_list_id === 7 && <li className="flexdown">
                       <span>特殊要求</span>
                       <p>
                           {detail.special_habit}
                       </p>
                   </li>
                }
                   {
                       detail.plate_list_id !== 8 ? <li className="flexdown">
                       <span>详情</span>
                       <p>
                           {detail.article}
                       </p>
                   </li> : ''
                   }
                    

                    <li className="flexdown">
                        <span>地址</span>
                        <p>
                            {detail.address}
                        </p>
                    </li>

                    {
                        ([2,3,4,14,15,16,17].includes(detail.plate_list_id)) && <li >
                        <span>有效期</span>
                        <p>
                            {detail.create_time.substring(0,10)} 至 {detail.over_time.substring(0,10)}
                        </p>
                    </li>
                    }

                   
                    <li>
                        <span>发布时间</span>
                        <p>
                            {detail.update_time ? getTime(detail.update_time) : '' }
                        </p>
                    </li>


                </ul>

                <br/>
                <br/>
                <br/>

            </div>

            <div className="detail-footer">
                <p>
                    <img alt="" src={detail.photo} />
                    {detail.nickname}
                </p>
                <p>
                    <span onClick={linkTo}><i className="like">
                        {
                            detail.isHymn ? <img alt="" src="./like.png" /> : <img alt="" src="./like_no.png" /> 
                        }
                    </i>{detail.hymn !== 0 ? detail.hymn : '点赞'}</span>
                    <span onClick={linkTo}>
                        <i className="start">
                            {
                                detail.isCollect ? <img alt="" src="./start_hover.png" /> : <img alt="" src="./start.png" /> 
                            }
                        </i>
                        {detail.collect !== 0 ? detail.collect : '收藏'}</span>
                </p>
            </div>

            <div id="mask" onClick={hideMask}>
                <img src ="./tip.png" alt='' />
            </div>

        </>
    )
}

export default Detail;