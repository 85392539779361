import React, { useEffect, useState } from 'react';
import SwiperCore, { Pagination,  A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../assets/swiper-bundle.min.css';
import axios from "axios";
import { linkToApp } from '../../assets/util';
import './index.css';

SwiperCore.use([Pagination, A11y]);

const Support = (props) => {
    const [detail, setDetail] = useState({});
    const [raising, setRaising] =  useState([]);
    const [gyArray, setGyArray] = useState([]);

    useEffect(() => {
        axios.post('/api/petRaisingH5', {
            id: Number(new URLSearchParams(props.history.location.search).get('pet_id')),
        }).then((res) => {
            if (res.data.code === 0) {
                setDetail(res.data.result);
                const petArray = [{
                    nickname: res.data.result.nickname,
                    photo: res.data.result.photo,
                    index: res.data.result.uid
                }]
                
                setRaising( res.data.result.raising);
                setGyArray([...petArray, ...res.data.result.raising])
               
            }

        })

    }, [props.history.location.search])

    const linkTo = () => {
        linkToApp();
    }

    const hideMask = () => {
        document.getElementById('mask').style.display = "none";
    }

    return (
        <>
            <header className="top-header">
                共养
            </header>
            <div className="support-top">
                <div className="support-top-left">
                    <p>
                        <img alt="" src={detail.pet_photo} />
                        {
                            raising.length > 0 && <span>共养</span> 
                        }
                    </p>
                    <strong>{detail.pet_name}</strong>
                </div>
            </div>

            <div className="support-bottom">
                <div className="support-bottom-top">
                    <Swiper
                       
                        pagination={
                            {
                                clickable: true,
                            }
                        }
                        slidesPerView={4}

                    >
                       
                        {
                           gyArray.map((item, index) => {
                                return <SwiperSlide key={index}> 
                                        <div key={index} className="swiper-slide swiper-support">
                                            <img alt="" src={item.photo} />
                                            <p className={index === 0 ? 'hgIcon' : ''}>{item.nickname}</p>
                                        </div>    
                                </SwiperSlide>
                            })

                        }


                    </Swiper>

                </div>
            </div>


            <p className="join-btn" onClick={linkTo}>
                <button className="button-j">同意加入</button>
            </p>

            <div id="mask" onClick={hideMask}>
                <img src ="./tip.png" />
            </div>


        </>
    )
}

export default Support;