import React from 'react';
import './index.css';



const Guide = () => {
    return (
        <>
            <div className='guide'>
                <h3>我想咨询：</h3>
                <h4>1、如何发起咨询？</h4>
                <p>在专家用户的咨询主页下方点击【我要咨询】按钮，编辑问题内容并付费后，及可向对方发起咨询。
                </p>

                <h4>2、发起咨询后我能做什么？</h4>
                <p>在发起咨询后，你需要等待对方回答您的问题，如果对方在7日内未作出回答，咨询费将在问题关闭7日内原路退回。
                </p>

                <h4>3、如果咨询没有被回答，如何退款？</h4>
                <p>发起付费咨询后，如过专家用户在7日内未回答，系统将在7日内按照原付款路径退款。
如果是通过微信支付的金额，会直接退回至您的微信钱包，可在微信中查看。
如果是通过支付宝支付的金额，会直接退回至您的支付宝，可在支付宝中查看。
如果是IOS渠道支付，将在扣除运营商手续费后，按平台实到金额进行退款。
                </p>

                <h4>4、首问发出后，如果专家用户敷衍回答，如何进行申诉？</h4>
                <p>点击【结束】按钮并作出相应评分，点击【个人中心】-【设置】-【投诉建议】提交对该咨询的投诉。客服将在24小时内回复。
                </p>

                <h4>5、如何保护自己的隐私？</h4>
                <p>专家用户在登记平台服务协议时，已签署用户隐私保证相关条款。懒洋葱平台在付费咨询中已将所有提问用户匿名，专家无法查阅提问者信息。
                </p>

                <h4>6、如何结束咨询？</h4>
                <p>专家回复后，用户可点击【结束】，结束当前咨询并打分。
                </p>

                <h4>7、如何进行追问？有何限制？</h4>
                <p>专家回复后，用户可点击【追问】，向专家追问并付费。最多可以追问10次。
                </p>

                <h4>8、如何浏览我咨询过的内容？</h4>
                <p>在【我的主页】选择【我的咨询】，即可浏览咨询过的内容。
                </p>


                <h3>我想回答：</h3>
                <h4>1、如何成为专家？</h4>
                <p>专家用户需拥有宠物相关领域的一定资质与经验，在平台进行实名认证，资格认证，签署相关身份协议后，方可成为【专家】，即可让其他用户向你发起付费咨询。
                </p>

                <h4>2、如何接受咨询？</h4>
                <p>当用户向你咨询时，你会收到相应的信息提示，也可以在【我的主页】-【付费咨询】】-【收到的咨询】中进行查看。
                </p>

                <h4>3、怎样设置我的收费项目？</h4>
                <p>您需要在【身份管理】中设置收费项目类别以及对应的问题引导模板，首问价格与追问价格。
                </p>

                <h4>4、为什么实际收到的钱比我设定的价格少？</h4>
                <p>因为第三方支付渠道会抽取渠道费用，所以实际的的收入会少于设定的价格，在接受问题前可以查看当前问题实际收入提示。
                </p>

                <h4>5、如遇恶意差评，怎么办？</h4>
                <p>在咨询页面选择【评分投诉】，并附上聊天截图与诉求，客服将在24小时内回复。
                </p>

                <h4>6、如何向其他用户推广？</h4>
                <p>
                    <li>①向其他用户展示自己亲自撰写的攻略。</li>
                    <li>②向其他专家用户交流自己的宠物相关领域经验，让其他专家协助引流。</li>
                    <li>③多在各平台展示自己，增加曝光机会。</li>
                </p>

                <h4>平台佣金</h4>
                <p>咨询所得将扣除总收入的10%作平台佣金，如提问人使用IOS渠道等第三方渠道，会再次扣除渠道费用，具体以实到金额为准。
追问与首问同理。
                </p>
            </div>


        </>
    )
}

export default Guide;