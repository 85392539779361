export const linkToApp = () => {
    let u = navigator.userAgent;
        const isWeixin = u.toLowerCase().indexOf('micromessenger') !== -1; // 微信内
        const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端
        const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
     

        if (isWeixin) {
            document.getElementById('mask').style.display = "block";
          
        } else {
            if (isAndroid) {
                 window.location.href = 'lyc-pet://';
                 setTimeout(function(){
                     let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden 
                     if(typeof hidden ==="undefined" || hidden ===false){
                         //应用宝下载地址 (emmm 找不到淘宝应用宝的地址，这里放的是 lucky coffee 地址)
                         // window.location.href ="https://a.app.qq.com/o/simple.jsp?pkgname=com.lucky.luckyclient";
                     }
                 }, 2000);

            } else if (isIOS) {
                window.location.href = 'LYC-Pet://';
                setTimeout(function(){
                    let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden 
                    if(typeof hidden ==="undefined" || hidden ===false){
                        //App store下载地址
                        // window.location.href = "http://itunes.apple.com/app/id387682726";
                    }
                }, 2000);
            }
        }
  
}

