import React from 'react';
import './index.css';



const Authentication = () => {
    return (
        <>
            <div className='authentication'>
                <div className='title'>
                    <span className='badge doctor'></span><span>医生</span>
                </div>
                <p>经过懒洋葱实名认证点亮资格的医生，已向平台提交执业兽医资格证，从业医院营业执照，签署医生协议，承诺用心为用户提供专业医师服务。
    用户可发布【日常】类型攻略贴，也可发布【健康】类型攻略贴。
    医疗救助范围推送功能即将上线。
                </p>

                <div className='title'>
                    <span className='badge trainer'></span>
                    <span>训宠师</span>
                </div>
                <p>经过懒洋葱实名认证点亮资格的训宠师，拥有一定实际训宠经验或拥有相应动物训练证书，可以解答训宠相关问题。
    用户可发布【日常】类型攻略贴，也可发布【训宠】类型攻略贴。
    包月功能扩充中。
                </p>

                <div className='title'>
                    <span className='badge breeder'></span>
                    <span>繁育师</span>
                </div>
                <p>经过懒洋葱实名认证点亮资格的繁育师，拥有一定实际繁育宠物的经验或拥有相应动物训练证书，可以为需要繁育的动物提供专业服务。
    用户可发布【日常】类型攻略贴，也可发布【繁育】类型攻略贴。
                </p>

                <div className='title'>
                    <span className='badge appraiser'></span>
                    <span>鉴定师</span>
                </div>
                <p>经过懒洋葱实名认证点亮资格的鉴定师，拥有大量动物鉴定经验，慧眼如炬。帮助用户初识动物，鉴定真伪。
    用户可发布【日常】类型攻略贴，也可发布【繁育】类型攻略贴。
                </p>

                <div className='title'>
                    <span className='badge homefostercare'></span>
                    <span>家庭寄养</span>
                </div>
                <p>经过懒洋葱实名认证点亮资格的家庭寄养，为用户提供便捷家庭寄养服务。但平台仅作为沟通交流平台，不提供服务担保，请用户自行甄别。
    用户可发布【日常】类型攻略贴，未经平台审核身份，严禁发布其他类型相关内容。
                </p>

                <div className='title'>
                    <span className='badge standin'></span>
                    <span>宠帮带</span>
                </div>
                <p>经过懒洋葱实名认证点亮资格的宠帮带，能够随身携带宠物并实时与宠主沟通联系，摈除宠物运输中因沟通不及时引发的纠纷。但平台仅作为沟通交流平台，不提供服务担保，请用户自行甄别。
    用户可发布【日常】类型攻略贴，未经平台审核身份，严禁发布其他类型相关内容。
                </p>

                <div className='title'>
                    <span className='badge homefeed'></span>
                    <span>上门喂养</span>
                </div>
                <p>经过懒洋葱实名认证点亮资格的上门喂养，能够为需要的用户提供上门喂养服务。但平台仅作为沟通交流平台，不提供服务担保，请用户自行甄别。
    用户可发布【日常】类型攻略贴，未经平台审核身份，严禁发布其他类型相关内容。
                </p>

                <div className='title'>
                    <span className='badge strayanimalrescue'></span>
                    <span>流浪动物救助</span>
                </div>
                <p>经过懒洋葱实名认证点亮资格的流浪动物救助，已提交相关社会团体组织证明或营业执照，给流浪动物找一个家。
    公益领养，批量记录功能即将上线。
    用户可发布【日常】类型攻略贴，未经平台审核身份，严禁发布其他类型相关内容。
                </p>
            </div>


        </>
    )
}

export default Authentication;