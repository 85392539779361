// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./assets/topbg.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n    padding: 0;\n    margin: 0;\n}\n#root {\n    height: 100%;\n}\n\nul, li {\n   list-style: none;\n}\n\n.top-header {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n    width: 100%;\n    height: 12vw;\n    color: #fff;\n    text-align: center;\n    font-size: 5vw;\n    background-size: cover;\n    line-height: 12vw;\n    font-style: normal;\n}", "",{"version":3,"sources":["webpack://src/index.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,SAAS;AACb;AACA;IACI,YAAY;AAChB;;AAEA;GACG,gBAAgB;AACnB;;AAEA;IACI,6DAA+C;IAC/C,WAAW;IACX,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":["* {\n    padding: 0;\n    margin: 0;\n}\n#root {\n    height: 100%;\n}\n\nul, li {\n   list-style: none;\n}\n\n.top-header {\n    background: url('./assets/topbg.png') no-repeat;\n    width: 100%;\n    height: 12vw;\n    color: #fff;\n    text-align: center;\n    font-size: 5vw;\n    background-size: cover;\n    line-height: 12vw;\n    font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
