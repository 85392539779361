import React, { useEffect } from 'react';
import './index.css';
import ReactFullpage from '@fullpage/react-fullpage';

const Home = () => {
   

    useEffect(() => {

    })

    return (
        <div>
            <div className="header">
                <img alt="" src='./logo_small.png' />
                <ul id="menu">
                    <li className="active"><a  href="#page1"><span></span>首页</a></li>
                    <li   ><a href="#page2"><span></span>关于我们</a></li>
                </ul>
            </div>
            <ReactFullpage
                //fullpage options
                scrollingSpeed={1000} /* Options here */
                navigation
                licenseKey = {'YOUR_KEY_HERE'}

                activeClass='active'
                 anchors={['page1', 'page2']}
               

                render={({ state, fullpageApi }) => {
                    return (


                        <ReactFullpage.Wrapper>
                            <div className="section">
                                <img className="phone" alt="" src="./page1.png" />
                                <div className="contents">
                                    <img className="small-logo" alt="" src="./logo_small.png" />
                                    <h3>懒洋葱</h3>
                                    {/* <a href='./../../assets/test.html'>12312312321232</a> */}
                                    <p>
                                        欢迎来到懒洋葱
                                        想要偷懒养宠？那就来懒洋葱~
            </p>
                                    <p>【流浪】【回家】【送养】【出售】你想给它一个什么样的主人？懒洋葱都能帮您让TA看到


            </p>
                                    <p>宠粮搭配，辩论台，问植物，试纸解读，化验单解读 将陆续上线。</p>
                                    <div className="download">
                                        <p style= {{ 'position' : 'relative' }}>
                                            <img alt="" className="img iphone" src="./iphone.png" />
                                            <div className="ios_download">
                                                <p>手机扫描下载</p>
                                                <img alt="" width='200px' width='200px' src="./ios_download.png" />
                                            </div>
                                        </p>
                                        <p style= {{ 'position' : 'relative' }}>
                                            <img alt="" className="img andior" src="./andior.png" />
                                            <div className="andior_download">
                                                <p>手机扫描下载</p>
                                                <img alt="" width='200px' width='200px' src="./andior_download.png" />
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="section">
                                <img className="phone" alt="" src="./page1.png" />
                                <div className="contents">

                                    <h3>关于我们</h3>
                                    <p>【寻宠】【领养】【指定】【配种】你想要的宠物，来懒洋葱都能找到
        </p>
                                    <p>
                                        遇到养宠问题不用怕，综合广大医生，训宠师，美容师等相关行业的超实用日更【笔记本】，让您不再需要带着问题搜寻毫无重点千篇一律的答案。

        </p>
                                    <p>宠物生病？想找医生？线下筛选医院，线上筛选医生，总有一位能解答您的疑惑



        </p>
                                    <p>与亲友共享宠物信息，“观察者”集中管理周围宠物信息。（如果要配图大概是两个人在商量。“居民”说有了懒洋葱，物管也能知道走丢的宠物是谁家的。“小主人”说有了懒洋葱，我再也不用老是问大主人什么时候做的驱虫）</p>
                                </div>
                            </div>
                        </ReactFullpage.Wrapper>

                    );
                }}
            />
            <div className='copyrightWrapper'>
                <div className="copyright1">渝ICP备2020014049号</div>
                <div className="copyright">
                    <div>
                        <a target="_blank" href=" " style={{ display:'inline-block','text-decoration':'none',height:'20px','line-height':'20px' }}>
                            <img src="./beian.png" width={20} height={20} style={{ float:'left'}} />
                            <p style={{ float:'left',height:'20px','line-height':'20px',margin:'0px 0px 0px 5px', color:'#939393' }}>
                                渝公网安备 50011802010606号
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;