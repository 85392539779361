import React, { useEffect, useState } from 'react';
import axios from 'axios';



const Protoal = () => {
    const [html, setHtml] = useState('');

    useEffect(() => {
        axios.get('/api/user/protocol').then((res) => {
            setHtml(res.data)
        })
    }, [])

    return (
        <>
          <div dangerouslySetInnerHTML = {{__html: html}} ></div>

        </>
    )
}

export default Protoal;